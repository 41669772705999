import { FILE_RESOURCE_BASE_FRAGMENT } from '@/graphql/_Fragments/FileResource/Base';
import ActionRule from '@/utils/enums/ActionRule';

export const SESSION_ENTITY_LIST_FRAGMENT = `
  fragment sessionEntityListFragment on Session {
    uid
    id
    schemaCode
    name
    __typename
    startTimestamp
    endTimestamp
    imageFileResource {
      ...fileResourceBaseFragment
    }
    _actions(actions: ["${ActionRule.CAN_BOOKMARK}"]) {
       value
       key
    }
    _isBookmarked(myUid: "%authUser%")
    _isInAgenda(myUid: "%authUser%")
  }
  ${FILE_RESOURCE_BASE_FRAGMENT}
`;
